.medeeimg {

  height: 300px;
  background-size: cover;
  background-repeat: no-repeat;

  animation-name: medeeimg;
  animation-duration: 5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.imgS {

  height: 300px;
  background-size: cover;
  background-repeat: no-repeat;

  animation-name: imgS;
  animation-duration: 10s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.img4 {

  height: 500px;
  background-size: cover;
  background-repeat: no-repeat;

  animation-name: img4;
  animation-duration: 10s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.imghatan {

  height: 800px;
  background-size: cover;
  background-repeat: no-repeat;

  animation-name: imghatan;
  animation-duration: 10s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.imghandmaa {

  height: 800px;
  background-size: cover;
  background-repeat: no-repeat;

  animation-name: imghandmaa;
  animation-duration: 10s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.imgjado {

  height: 800px;
  background-size: cover;
  background-repeat: no-repeat;

  animation-name: imgjado;
  animation-duration: 10s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.imgsurgalt {

  height: 800px;
  background-size: cover;
  background-repeat: no-repeat;

  animation-name: imgsurgalt;
  animation-duration: 10s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.imgmeeting {

  height: 800px;
  background-size: cover;
  background-repeat: no-repeat;

  animation-name: imgmeeting;
  animation-duration: 10s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.imgunshilaga {

  height: 800px;
  background-size: cover;
  background-repeat: no-repeat;

  animation-name: imgunshilaga;
  animation-duration: 10s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.news {
  height: 500px;
  background-size: cover;
  background-repeat: no-repeat;

  animation-name: News;
  animation-duration: 20s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.imgsurgalt2024 {

  height: 400px;
  background-size: cover;
  background-repeat: no-repeat;

  animation-name: imgsurgalt2024;
  animation-duration: 10s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes medeeimg {

  0% {
    background-image: url("../../../public/odriin_bayr.jpg");
  }

  100% {
    background-image: url("../../../public/odriin_bayr2.jpg");
  }
}

@keyframes imgS {

  0% {
    background-image: url("../../../public/baishin.jpg");
  }

  25% {
    background-image: url("../../../public/baishin1.jpg");
  }

  50% {
    background-image: url("../../../public/baishin2.jpg");
  }

  100% {
    background-image: url("../../../public/baishin3.jpg");
  }
}

@keyframes img4 {

  0% {
    background-image: url("../../../public/medee4\(1\).jpg");
  }

  50% {
    background-image: url("../../../public/medee4\(2\).jpg");
  }

  100% {
    background-image: url("../../../public/medee4\(3\).jpg");
  }
}

@keyframes imghatan {

  0% {
    background-image: url("../../../public/hatan.jpg");
  }

  100% {
    background-image: url("../../../public/hatan.jpg");
  }
}

@keyframes imghandmaa {

  0% {
    background-image: url("../../../public/handmaa.jpg");
  }

  100% {
    background-image: url("../../../public/handmaa.jpg");
  }
}

@keyframes imgjado {

  0% {
    background-image: url("../../../public/jado.jpg");
  }


  100% {
    background-image: url("../../../public/jado1.jpg");
  }
}

@keyframes imgsurgalt {

  0% {
    background-image: url("../../../public/surgalt2.jpg");
  }

  100% {
    background-image: url("../../../public/surgalt1.jpg");
  }
}

@keyframes imgmeeting {

  0% {
    background-image: url("../../../public/meeting.jpg");
  }

  100% {
    background-image: url("../../../public/meeting.jpg");
  }
}

@keyframes imgsurgalt2024 {

  0% {
    background-image: url("../../../public/viber_image_2024-01-10_15-38-22-964.jpg");
  }

  100% {
    background-image: url("../../../public/viber_image_2024-01-10_15-38-31-009.jpg");
  }
}

@keyframes imgunshilaga {

  0% {
    background-image: url("../../../public/ticket.jpg");
  }

  100% {
    background-image: url("../../../public/ticket.jpg");
  }
}


@keyframes News {

  0% {
    background-image: url("../../../public/news.jpg");
  }

  20% {
    background-image: url("../../../public/news7.jpg");
  }

  40% {
    background-image: url("../../../public/news8.jpg");
  }

  60% {
    background-image: url("../../../public/news_photo.jpg");
  }

  80% {
    background-image: url("../../../public/news_buddha1.jpg");
  }

  100% {
    background-image: url("../../../public/news2.jpg");
  }

}



