*{
    margin: 0;
    padding: 0;
}
h6>a{
    font-size: 14px;
   
}
a>img{
    width: 150px;
    padding-right: 20px;
}
div>a{
    color: #f47a20;
}
.navs{
    padding-top: 140px ;
}
.nav-item{
    margin-bottom: 20px;
}
.rows>div>ul{
    margin-top: 150px;
}

@media (max-width: 500px) {
    .medeeD{
    display: flex;
    flex-direction: column-reverse;
    }
}
@media (width: 920px) {
    .post-detail-wrapper{
        padding: 2px;
    }
    
}

