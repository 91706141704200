.medeeimg {

    height: 300px;
    background-size: cover;
    background-repeat: no-repeat;

  animation-name: medeeimg;
  animation-duration: 5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes medeeimg {
  
  0% {
    background-image: url("../../../public/assets/img/buddha_images/IMG_6885.JPG");
  }
  100% {
    background-image: url("../../../public/assets/img/buddha_images/IMG_6885.JPG");
  }
  
}