*{
    margin: 0;
    padding: 0;
}
.main{
    width: 100vw;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.main-div{
    display: flex;
    }
.main-div>div>img{
    width: 100%;
    padding: 10px;
    cursor: pointer;
}
.zuragnuud{
    width: 33%;
}
@media (max-width: 920px) {
    .main-div>div>img{
        width: 100%;
        padding: 5px;
    }
}@media (max-width: 575px) {
    .main-div>div>img{
        width: 100%;
        padding: 2px;
    }
}
.imgs{
    width: 100vw;
    height: 100vh;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.568);
    top: 0;
    z-index: 10;
    padding: 100px ;
    
}
.imgF{
    width: 100vw;
    height: 100vh;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.568);
    top: 0;
    z-index: 10;

    
    
}
.imgF>img{
    position: relative;
    height: 100%;
}