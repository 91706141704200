.banner {
    animation-name: images;
    animation-duration: 10s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }
  @keyframes images {
    /* 0% {
      background-image: url("../../../public/assets/img/banner/1.jpg");
    } */
  
    /* 20% {
      background-image: url("../../../public/assets/img/banner/2.jpg");
    } */
  
    0% {
      background-image: url("../../../public/assets/img/banner/3.jpg");
    }
  
    60% {
      background-image: url("../../../public/assets/img/banner/4.jpg");
    }
  
    100% {
      background-image: url("../../../public/assets/img/banner/5.jpg");
    }
  }