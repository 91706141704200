.donate-page {
  width: 100%;
  height: 120vh;
  position: absolute;
}

.donate-page .image-container {
  position: absolute;
  z-index: -1;
  height: 120vh;
  overflow: hidden;
}

.donate-page .image-container img {
  width: 100%;
  object-fit: cover;
  object-position: center;
  min-height: 100vh;
}

.donate-page .donate-form-container {
  width: 560px;
  background-color: #ffffffe3;
  padding: 32px;
  border-radius: 12px;
  float: right;
  margin-top: calc(100vh / 2 - 384.5px);
  margin-right: 15%;
}

.donate-page .donate-tab {
  /* border: 0.5px solid #4a0602; */
  padding: 6px 10px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.donate-tab .tab-headers {
  display: flex;
  flex-direction: row;
  gap: 0.125em;
}

.donate-tab .tab-headers .tab-header-items {
  flex: 1;
  justify-content: center;
  display: flex;
  cursor: pointer;
  transition: all 0.5s;
  padding: 8px 12px;
  border-radius: 3px;
  font-weight: bold;
}

.donate-tab .tab-headers .tab-header-items:hover,
.tab-header-items-active {
  background-color: #f47a20;
  color: white;
}

.donate-tab .account-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.donate-tab .account-item .label {
  font-size: 16px;
  color: #f47a20;
}

.donate-tab .account-item .text {
  font-size: 18px;
  /* color: #f47a20; */
  font-weight: bold;
}
.swif-div{
  border: 1px white solid;
  margin-top: 10px;
  border-radius: 8px;
}
.swif{
  background-color: white;
  padding: 15px 25px;
  width: 100%;
  border-radius: 8px;
  
}
.p-text{
  font-size: 14px;
  padding: 0px 25px;
  width: auto;
}
.suggest-div{
  border: red solid 1px;
  border-radius: 8px;
  padding: 0px 25px;
  margin-top: 20px;
}
.sug-title{
  color: red;
}
.sug-text{
  font-size: 13px;
}

@media only screen and (max-width: 1024px) {
  .donate-page .image-container img {
    width: 100%;
    object-fit: cover;
    object-position: center;
  }

  .donate-page .donate-form-container {
    margin: auto;
    /* margin-top: calc(100vh / 2 - 384.5px); */
    float: none;
    min-height: 100vh;
    border-radius: none;
  }
}

@media only screen and (max-width: 720px) {
  .donate-page {
    width: 100%;
    height: auto;
  }

  .donate-page .image-container {
    display: none;
  }

  .donate-page .donate-form-container {
    padding: 12px 24px;
    min-height: 100vh;
    width: 100%;
  }
  .swif-div{
    border: rgb(235, 235, 235) solid 1px;
  }
  .swif{
    background-color: rgb(235, 234, 234);
  }
}
